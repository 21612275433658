import React, { useState, useCallback, useEffect } from 'react';
import { STICKY_COLORS } from './constants/colors';
import { Calculator as CalculatorType, LoanCalculator as LoanCalculatorType, RepaymentMethod } from './constants/types';
import Calculator from './components/Calculator';
import LoanCalculator from './components/LoanCalculator';

const MultiInstanceIntelligentCalculator: React.FC = () => {
  const [calculators, setCalculators] = useState<(CalculatorType | LoanCalculatorType)[]>([]);
  const [nextZIndex, setNextZIndex] = useState(1);
  const [selectedCalculatorId, setSelectedCalculatorId] = useState<number | null>(null);
  const [lastAddedCalculatorId, setLastAddedCalculatorId] = useState<number | null>(null);
  const [showAutoVariables, setShowAutoVariables] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [buildTime, setBuildTime] = useState<string>('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const currentBuildTime = process.env.REACT_APP_BUILD_TIME as string;
    console.log('Current build time:', currentBuildTime);
    if (currentBuildTime) {
      const formattedTime = new Date(currentBuildTime).toLocaleString();
      setBuildTime(formattedTime);
    } else {
      setBuildTime('未知');
    }
  }, []);

  useEffect(() => {
    if (calculators.length === 0) {
      const newId = 1;
      const randomColor = STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)][0];
      const defaultWidth = 400;
      const mobileWidth = Math.min(300, window.innerWidth * 0.9);
      
      setCalculators([{ 
        id: newId, 
        topColor: randomColor,
        position: { x: 100, y: 100 },
        size: { 
          width: isMobile ? mobileWidth : defaultWidth,
          height: isMobile ? 250 : 300
        },
        zIndex: 1
      }]);
      setNextZIndex(2);
      setSelectedCalculatorId(newId);
      setLastAddedCalculatorId(newId);
    }
  }, []); // 空依赖数组，确保只在组件挂载时运行一次

  const addCalculator = useCallback(() => {
    setCalculators(prev => {
      const newId = Math.max(...prev.map(c => c.id), 0) + 1;
      const randomColor = STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)][0];
      const defaultWidth = 400;
      const mobileWidth = Math.min(300, window.innerWidth * 0.9);
      
      return [...prev, { 
        id: newId, 
        topColor: randomColor,
        position: { x: 100 + Math.random() * 100, y: 100 + Math.random() * 100 },
        size: { 
          width: isMobile ? mobileWidth : defaultWidth,
          height: isMobile ? 250 : 300
        },
        zIndex: nextZIndex
      }];
    });
    setNextZIndex(prev => prev + 1);
    setSelectedCalculatorId(prev => {
      const newId = Math.max(...calculators.map(c => c.id), 0) + 1;
      return newId;
    });
    setLastAddedCalculatorId(prev => {
      const newId = Math.max(...calculators.map(c => c.id), 0) + 1;
      return newId;
    });
  }, [nextZIndex, isMobile]);

  const addLoanCalculator = useCallback(() => {
    const newId = Math.max(...calculators.map(c => c.id), 0) + 1;
    const randomColor = STICKY_COLORS[Math.floor(Math.random() * STICKY_COLORS.length)][0];
    setCalculators(prev => [...prev, { 
      id: newId, 
      topColor: randomColor,
      position: { x: 100 + Math.random() * 100, y: 100 + Math.random() * 100 },
      size: isMobile ? { width: 300, height: 400 } : { width: 480, height: 680 },
      zIndex: nextZIndex,
      loanAmount: 0,
      loanTerm: 0,
      annualInterestRate: 0,
      repaymentMethod: 'equal' as RepaymentMethod
    }]);
    setNextZIndex(prev => prev + 1);
    setSelectedCalculatorId(newId);
    setLastAddedCalculatorId(newId);
  }, [calculators, nextZIndex, isMobile]);

  const removeCalculator = useCallback((id: number) => {
    setCalculators(prev => prev.filter(calc => calc.id !== id));
    if (selectedCalculatorId === id) {
      setSelectedCalculatorId(prev => {
        const remainingCalculators = calculators.filter(calc => calc.id !== id);
        return remainingCalculators.length > 0 ? remainingCalculators[remainingCalculators.length - 1].id : -1;
      });
    }
    if (lastAddedCalculatorId === id) {
      setLastAddedCalculatorId(null);
    }
  }, [calculators, selectedCalculatorId, lastAddedCalculatorId]);

  const updateCalculator = useCallback((id: number, updates: Partial<CalculatorType | LoanCalculatorType>) => {
    setCalculators(prev => prev.map(calc => 
      calc.id === id ? { ...calc, ...updates } : calc
    ));
  }, []);

  const bringToFront = useCallback((id: number) => {
    setCalculators(prev => prev.map(calc => 
      calc.id === id ? { ...calc, zIndex: nextZIndex } : calc
    ));
    setNextZIndex(prev => prev + 1);
    setSelectedCalculatorId(id);
  }, [nextZIndex]);

  return (
    <div className="relative w-full h-screen bg-gray-100 overflow-hidden">
      <div className="absolute top-4 left-4 z-50 flex space-x-4">
        <button 
          onClick={addCalculator}
          className="border border-gray-300 hover:bg-gray-100 text-gray-700 font-medium py-2 px-4 rounded-full shadow-sm"
        >
          +笔记计算器
        </button>
        <button 
          onClick={addLoanCalculator}
          className="border border-gray-300 hover:bg-gray-100 text-gray-700 font-medium py-2 px-4 rounded-full shadow-sm"
        >
          +贷款计算器
        </button>
      </div>
      {calculators.map((calc) => (
        'loanAmount' in calc ? (
          <LoanCalculator 
            key={calc.id} 
            {...calc}
            onClose={removeCalculator}
            updateCalculator={updateCalculator}
            bringToFront={bringToFront}
            isSelected={calc.id === selectedCalculatorId}
            autoFocus={calc.id === lastAddedCalculatorId}
            isMobile={isMobile}
          />
        ) : (
          <Calculator 
            key={calc.id} 
            {...calc}
            onClose={removeCalculator}
            updateCalculator={updateCalculator}
            bringToFront={bringToFront}
            isSelected={calc.id === selectedCalculatorId}
            autoFocus={calc.id === lastAddedCalculatorId}
            showAutoVariables={showAutoVariables}
            isMobile={isMobile}
          />
        )
      ))}
      <div className="absolute bottom-4 left-4 z-50 flex items-center">
        <input
          type="checkbox"
          id="showAutoVariables"
          checked={showAutoVariables}
          onChange={(e) => setShowAutoVariables(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="showAutoVariables" className="text-gray-700 select-none">
          auto-var
        </label>
      </div>
      <div className="absolute bottom-4 right-4 text-xs text-gray-400">
        最新构建时间: {buildTime}
      </div>
      {isMobile && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-xs text-gray-400">
          mobile
        </div>
      )}
    </div>
  );
};

export default MultiInstanceIntelligentCalculator;