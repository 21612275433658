import React, { useState, useCallback, useRef, useEffect } from 'react';
import { X, ArrowRight } from 'lucide-react';
import { CalculatorProps } from '../constants/types';
import { calculateResult, evaluateExpression } from '../utils/calculationUtils';
import ColorPicker from './ColorPicker';

const Calculator: React.FC<CalculatorProps> = ({ 
  onClose, id, updateCalculator, topColor, position, zIndex, bringToFront, size, isSelected, autoFocus, showAutoVariables, isMobile 
}) => {
  const [text, setText] = useState('');
  const [lines, setLines] = useState<{ id: number; content: string; result: { value: number | string; isComment: boolean; isString: boolean } }[]>([]);
  const [total, setTotal] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [flashMessage, setFlashMessage] = useState('');
  const [localSize, setLocalSize] = useState(size);
  const calculatorRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autoFocus && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [autoFocus]);

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText);
    
    const newLines = newText.split('\n').map((content, index) => ({
      id: index,
      content,
      result: { value: NaN, isComment: false, isString: false }
    }));
    
    const variables: Record<string, number | string> = {};
    const calculatedLines = newLines.map((line, index) => ({
      ...line,
      result: calculateResult(line.content, index, variables)
    }));
    
    setLines(calculatedLines);
    setTotal(calculatedLines.reduce((sum, line) => sum + (isNaN(line.result.value as number) ? 0 : line.result.value as number), 0));
  }, []);

  const formatResult = (result: { value: number | string; isComment: boolean; isString: boolean }) => {
    if (result.isString) {
      return result.value as string;
    }
    if (typeof result.value === 'number' && !isNaN(result.value)) {
      const [intPart, decimalPart] = result.value.toFixed(2).split('.');
      return (
        <span>
          {intPart}
          <span className="text-[0.8em] opacity-80">.{decimalPart}</span>
        </span>
      );
    }
    return '';
  };

  const handleStart = (e: React.MouseEvent | React.TouchEvent) => {
    bringToFront(id);
    if (calculatorRef.current && (e.target === calculatorRef.current || (e.target as HTMLElement).closest('.calculator-header'))) {
      setIsDragging(true);
      const rect = calculatorRef.current.getBoundingClientRect();
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      setDragOffset({
        x: clientX - rect.left,
        y: clientY - rect.top
      });
    } else if ((e.target as HTMLElement).classList.contains('resize-handle')) {
      setIsResizing(true);
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isDragging || isResizing) {
      const handleMove = (e: MouseEvent | TouchEvent) => {
        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        if (isDragging) {
          const newX = clientX - dragOffset.x;
          const newY = clientY - dragOffset.y;
          updateCalculator(id, { position: { x: newX, y: newY } });
        } else if (isResizing && calculatorRef.current) {
          const rect = calculatorRef.current.getBoundingClientRect();
          const newWidth = Math.max(300, clientX - rect.left);
          const newHeight = Math.max(200, clientY - rect.top);
          setLocalSize({ width: newWidth, height: newHeight });
        }
      };
      const handleEnd = () => {
        setIsDragging(false);
        setIsResizing(false);
      };
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleEnd);
      window.addEventListener('touchmove', handleMove);
      window.addEventListener('touchend', handleEnd);
      return () => {
        window.removeEventListener('mousemove', handleMove);
        window.removeEventListener('mouseup', handleEnd);
        window.removeEventListener('touchmove', handleMove);
        window.removeEventListener('touchend', handleEnd);
      };
    }
  }, [isDragging, isResizing, dragOffset, id, updateCalculator]);

  const showFlashMessage = (message: string) => {
    setFlashMessage(message);
    setTimeout(() => setFlashMessage(''), 3000);
  };

  const exportToMarkdown = () => {
    const content = lines.map(line => {
      if (line.result.isComment) {
        return line.content;
      } else {
        return `${line.content} = ${formatResult(line.result)}`;
      }
    }).join('\n');

    const markdownContent = `# Calculator Export\n\n${content}\n\n## Total: ${total.toFixed(2)}`;
    
    const blob = new Blob([markdownContent], { type: 'text/markdown;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `calculator_export_${id}.md`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    showFlashMessage('Export Succ! 🍺');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText);
    handleTextChange(e);

    // 同步滚动位置
    if (contentRef.current && e.target) {
      contentRef.current.scrollTop = e.target.scrollTop;
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLTextAreaElement>) => {
    if (contentRef.current && e.currentTarget) {
      contentRef.current.scrollTop = e.currentTarget.scrollTop;
    }
  };

  return (
    <div 
      ref={calculatorRef}
      className="absolute bg-white rounded-lg shadow-md flex flex-col"
      style={{ 
        left: position.x, 
        top: position.y, 
        zIndex,
        width: localSize.width,
        height: localSize.height,
        border: isSelected ? `2px solid ${topColor}` : 'none',
        minWidth: isMobile ? '280px' : '300px', // 添加最小宽度
        maxWidth: isMobile ? '90vw' : 'none' // 在移动设备上限制最大宽度
      }}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
    >
      <div className="flex justify-between items-center p-2 rounded-t-lg calculator-header" style={{ backgroundColor: topColor, cursor: 'move' }}>
        <h2 className="text-lg font-semibold text-gray-800">NoteCal</h2>
        <button className="text-gray-800" onClick={() => onClose(id)}>
          <X size={20} />
        </button>
      </div>
      <div className="relative font-mono p-4 flex-grow overflow-hidden" style={{ height: 'calc(100% - 80px)' }}>
        {showAutoVariables && (
          <div 
            className="absolute left-[36px] top-0 bottom-0 w-px bg-gray-300"
            style={{
              height: '100%',
            }}
          ></div>
        )}
        <textarea
          ref={textareaRef}
          value={text}
          onChange={handleInputChange}
          onScroll={handleScroll}
          className="absolute inset-0 w-full h-full bg-transparent outline-none resize-none overflow-auto"
          style={{ 
            paddingLeft: showAutoVariables ? '44px' : '4px',
            color: 'transparent', 
            caretColor: 'black',
            lineHeight: '1.5625rem',
            backgroundImage: 'linear-gradient(to bottom, rgba(229, 231, 235, 0.8) 1px, transparent 1px)',
            backgroundSize: '100% 1.5625rem',
            backgroundPosition: '0 -1px',
          }}
          placeholder="Start typing...e.g., 3+2"
        />
        <div 
          ref={contentRef}
          className="absolute inset-0 overflow-auto pointer-events-none"
          style={{ 
            paddingRight: '22px', 
            paddingLeft: showAutoVariables ? '44px' : '4px',
            lineHeight: '1.5625rem',
            backgroundImage: 'linear-gradient(to bottom, rgba(229, 231, 235, 0.8) 1px, transparent 1px)',
            backgroundSize: '100% 1.5625rem',
            backgroundPosition: '0 -1px',
          }}
        >
          {lines.map((line, index) => (
            <div 
              key={index} 
              className="flex justify-between items-start relative"
              style={{ height: '1.5625rem' }}
            >
              {showAutoVariables && (
                <span className="absolute left-[-39px] text-gray-400 select-none" style={{ 
                  fontSize: '0.8em',
                  top: '2px'
                }}>
                  r{index + 1}
                </span>
              )}
              <span style={{ 
                color: line.result.isComment ? '#cccccc' : 'black',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all'
              }}>
                {line.content}
              </span>
              <span className="text-green-600 pl-2 whitespace-nowrap">
                {!line.result.isComment && formatResult(line.result)}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute bottom-4 left-0 right-4 flex justify-between items-center text-gray-500">
        <div className="w-[36px] flex justify-center">
          <ColorPicker 
            selectedColor={topColor}
            onColorSelect={(color) => updateCalculator(id, { topColor: color })}
          />
        </div>
        <div className="flex-grow text-center">Total: {total.toFixed(2)}</div>
        <button onClick={exportToMarkdown} className="text-gray-800 focus:outline-none">
          <ArrowRight size={20} />
        </button>
      </div>
      {flashMessage && (
        <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-full transition-opacity duration-300">
          {flashMessage}
        </div>
      )}
      <div 
        className="absolute bottom-0 right-0 w-4 h-4 cursor-se-resize resize-handle"
        style={{ background: 'linear-gradient(135deg, transparent 50%, rgba(0,0,0,0.3) 50%)' }}
      ></div>
    </div>
  );
};

export default Calculator;