import { addDays, addMonths, addYears, parse, format } from 'date-fns';

export const calculateResult = (line: string, lineIndex: number, variables: Record<string, number | string>) => {
    const trimmedLine = line.trim();
    console.log('Input:', trimmedLine);
  
    if (trimmedLine.startsWith('#') || trimmedLine.startsWith('//')) {
      return { value: NaN, isComment: true, isString: false };
    }
  
    try {
      // 处理变量赋值
      const assignmentMatch = trimmedLine.match(/^(\w+)\s*=\s*(.+)$/);
      if (assignmentMatch) {
        const [, varName, expression] = assignmentMatch;
        const value = evaluateExpression(expression, variables);
        variables[varName] = value;
        variables[`r${lineIndex + 1}`] = value;
        return { value, isComment: false, isString: typeof value === 'string' };
      }
  
      // 处理其他表达式（包括日期计算和普通计算）
      const value = evaluateExpression(trimmedLine, variables);
      variables[`r${lineIndex + 1}`] = value;
      return { value, isComment: false, isString: typeof value === 'string' };
    } catch (error) {
      console.error('Calculation error:', error);
      return { value: NaN, isComment: false, isString: false };
    }
};

export const evaluateExpression = (expression: string, variables: Record<string, number | string>): number | string => {
    const trimmedExpression = expression.trim();

    // 检查是否为变量引用
    if (/^r\d+$/.test(trimmedExpression) || variables.hasOwnProperty(trimmedExpression)) {
      return variables[trimmedExpression] ?? NaN;
    }
  
    // 检查是否为日期表达式或日期运算
    const dateRegex = /^(today|tomorrow|yesterday|now|r\d+)(\s*[+-]\s*\d+\s*(d|day|days|m|month|months|y|year|years))*$/i;
    if (dateRegex.test(trimmedExpression)) {
      return calculateDate(trimmedExpression, variables);
    }
  
    // 检查是否为单个数字
    if (/^-?\d+(\.\d+)?$/.test(trimmedExpression)) {
      return parseFloat(trimmedExpression);
    }
  
    // 处理普通表达式
    try {
      const expressionWithVariables = replaceVariables(trimmedExpression, variables);
      const expressionWithPower = expressionWithVariables.replace(/\^/g, '**');
      const expressionWithPercentage = expressionWithPower.replace(/(\d+(\.\d+)?)\s*%/g, '($1/100)');
      const result = eval(expressionWithPercentage);
      return typeof result === 'number' && isNaN(result) ? NaN : result;
    } catch (error) {
      console.error('Expression evaluation error:', error);
      return NaN;
    }
};

export const validateInterestRate = (value: string): boolean => {
  if (value === '') return true;
  const numberRegex = /^\d*\.?\d*$/;
  if (!numberRegex.test(value)) return false;
  const numValue = parseFloat(value);
  return !isNaN(numValue) && numValue >= 0 && numValue <= 100;
};

// 如果还有其他计算相关的工具函数，也可以放在这里

const calculateDate = (expression: string, variables: Record<string, number | string>): string => {
  const parts = expression.split(/\s*([+-])\s*/);
  let date = new Date();

  for (let i = 0; i < parts.length; i += 2) {
    const part = parts[i].toLowerCase();
    const operation = parts[i + 1];
    const value = parseInt(parts[i + 2], 10);

    if (part === 'today' || part === 'now') {
      // 保持当前日期不变
    } else if (part === 'tomorrow') {
      date = addDays(date, 1);
    } else if (part === 'yesterday') {
      date = addDays(date, -1);
    } else if (part.startsWith('r')) {
      const varValue = variables[part];
      if (typeof varValue === 'string') {
        date = parse(varValue, 'yyyy-MM-dd', new Date());
      }
    }

    if (operation === '+' || operation === '-') {
      const unit = parts[i + 2].slice(-1).toLowerCase();
      const amount = operation === '+' ? value : -value;

      switch (unit) {
        case 'd':
          date = addDays(date, amount);
          break;
        case 'm':
          date = addMonths(date, amount);
          break;
        case 'y':
          date = addYears(date, amount);
          break;
      }
    }
  }

  return format(date, 'yyyy-MM-dd');
};

const replaceVariables = (expression: string, variables: Record<string, number | string>): string => {
  return expression.replace(/\b([a-zA-Z]\w*)\b/g, (match, varName) => {
    if (variables.hasOwnProperty(varName)) {
      const value = variables[varName];
      return typeof value === 'string' ? `"${value}"` : value.toString();
    }
    return match;
  });
};