import React, { useState } from 'react';
import { Circle } from 'lucide-react';
import { STICKY_COLORS } from '../constants/colors';
import { ColorPickerProps } from '../constants/types';

const ColorPicker: React.FC<ColorPickerProps> = ({ selectedColor, onColorSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative z-[9999]">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-gray-800 focus:outline-none"
      >
        <Circle size={20} fill={selectedColor} stroke={selectedColor} />
      </button>
      {isOpen && (
        <div className="absolute bottom-full left-0 mb-2 bg-white rounded-lg shadow-md p-4" style={{ width: '200px' }}>
          {STICKY_COLORS.map(([color, name]) => (
            <button
              key={color}
              className="w-full flex items-center space-x-2 p-2 hover:bg-gray-100 rounded"
              onClick={() => {
                onColorSelect(color);
                setIsOpen(false);
              }}
            >
              <div className="w-6 h-6 rounded" style={{ backgroundColor: color }} />
              <span>{name}</span>
              {selectedColor === color && <span className="ml-auto">✓</span>}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;