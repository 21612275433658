import React, { useState, useRef, useCallback, useEffect } from 'react';
import { X } from 'lucide-react';
import { LoanCalculator as LoanCalculatorType, RepaymentMethod } from '../constants/types';
import { validateInterestRate } from '../utils/calculationUtils';
import ColorPicker from './ColorPicker';

interface LoanCalculatorProps extends LoanCalculatorType {
  onClose: (id: number) => void;
  updateCalculator: (id: number, updates: Partial<LoanCalculatorType>) => void;
  bringToFront: (id: number) => void;
  isSelected: boolean;
  autoFocus: boolean;
  onColorChange?: (color: string) => void;
  isMobile: boolean;
}

const COMPARISON_WIDTH_THRESHOLD = 750;

const LoanCalculator: React.FC<LoanCalculatorProps> = ({ 
  id, topColor, position, size, zIndex, loanAmount, loanTerm, annualInterestRate, repaymentMethod,
  onClose, updateCalculator, bringToFront, isSelected, autoFocus, onColorChange, isMobile
}) => {
  const [localSize, setLocalSize] = useState(size);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [loanAmountInput, setLoanAmountInput] = useState(loanAmount ? loanAmount.toString() : '');
  const [amountScale, setAmountScale] = useState<string | null>(null);
  const calculatorRef = useRef<HTMLDivElement>(null);
  const loanAmountRef = useRef<HTMLInputElement>(null);
  const loanTermRef = useRef<HTMLInputElement>(null);
  const interestRateRef = useRef<HTMLInputElement>(null);
  const repaymentMethodRef = useRef<HTMLSelectElement>(null);
  const [showComparison, setShowComparison] = useState(false);
  const [leftMethod, setLeftMethod] = useState<RepaymentMethod>('equal');
  const [rightMethod, setRightMethod] = useState<RepaymentMethod>('principal');
  const [currentSingleMethod, setCurrentSingleMethod] = useState<RepaymentMethod>('equal');
  const [schedule, setSchedule] = useState<any[]>([]);
  const [interestRateInput, setInterestRateInput] = useState(annualInterestRate ? annualInterestRate.toString() : '');
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  
  const methods: RepaymentMethod[] = ['equalPrincipalInterest', 'equal', 'principal', 'interestFirst'];

  useEffect(() => {
    if (showComparison) {
      const currentIndex = methods.indexOf(currentSingleMethod);
      if (currentIndex === methods.length - 1) {
        setLeftMethod(methods[methods.length - 2]);
        setRightMethod(currentSingleMethod);
      } else {
        setLeftMethod(currentSingleMethod);
        setRightMethod(methods[currentIndex + 1]);
      }
    } else {
      // 当切换回单一模式时，使用当前左侧方法
      setCurrentSingleMethod(leftMethod);
      updateCalculator(id, { repaymentMethod: leftMethod });
    }
  }, [showComparison]);

  const handleMethodClick = (method: RepaymentMethod) => {
    if (!showComparison) {
      setCurrentSingleMethod(method);
      setLeftMethod(method); // 更新左侧方法，以便在切换到比较模式时使用
      updateCalculator(id, { repaymentMethod: method });
      return;
    }

    const clickedIndex = methods.indexOf(method);
    const leftIndex = methods.indexOf(leftMethod);
    const rightIndex = methods.indexOf(rightMethod);

    if (method === leftMethod) {
      const newRightIndex = (leftIndex + 1) % methods.length;
      setRightMethod(methods[newRightIndex]);
    } else if (method === rightMethod) {
      const newLeftIndex = (rightIndex - 1 + methods.length) % methods.length;
      setLeftMethod(methods[newLeftIndex]);
    } else if (clickedIndex < leftIndex || (clickedIndex > rightIndex && rightIndex < leftIndex)) {
      setLeftMethod(method);
    } else {
      setRightMethod(method);
    }
  };

  const getAmountScale = (amount: number): string => {
    if (amount >= 100000000) return '亿';
    if (amount >= 10000000) return '千万';
    if (amount >= 1000000) return '百万';
    if (amount >= 100000) return '十万';
    if (amount >= 10000) return '万';
    if (amount >= 1000) return '千';
    return '';
  };

  useEffect(() => {
    setAmountScale(getAmountScale(loanAmount || 0));
  }, [loanAmount]);

  const calculateSchedule = useCallback((method: RepaymentMethod) => {
    if (loanAmount && loanTerm && annualInterestRate && loanAmount > 0 && loanTerm > 0 && annualInterestRate > 0) {
      const monthlyRate = annualInterestRate / 12 / 100;
      let newSchedule = [];
      let balance = loanAmount;

      switch (method) {
        case 'equal':
          const monthlyPayment = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, loanTerm)) / (Math.pow(1 + monthlyRate, loanTerm) - 1);
          for (let month = 1; month <= loanTerm; month++) {
            const interest = balance * monthlyRate;
            const principal = monthlyPayment - interest;
            balance -= principal;
            newSchedule.push({ month, payment: monthlyPayment, principal, interest, balance });
          }
          break;

        case 'principal':
          const principalPayment = loanAmount / loanTerm;
          for (let month = 1; month <= loanTerm; month++) {
            const interest = balance * monthlyRate;
            const payment = principalPayment + interest;
            balance -= principalPayment;
            newSchedule.push({ month, payment, principal: principalPayment, interest, balance });
          }
          break;

        case 'equalPrincipalInterest':
          const monthlyPrincipal = loanAmount / loanTerm;
          const monthlyInterest = loanAmount * monthlyRate;
          const fixedMonthlyPayment = monthlyPrincipal + monthlyInterest;
          for (let month = 1; month <= loanTerm; month++) {
            balance -= monthlyPrincipal;
            newSchedule.push({ 
              month, 
              payment: fixedMonthlyPayment, 
              principal: monthlyPrincipal, 
              interest: monthlyInterest, 
              balance 
            });
          }
          break;

        case 'interestFirst':
          const monthlyInterestOnly = loanAmount * monthlyRate;
          for (let month = 1; month <= loanTerm; month++) {
            if (month === loanTerm) {
              newSchedule.push({ 
                month, 
                payment: loanAmount + monthlyInterestOnly, 
                principal: loanAmount, 
                interest: monthlyInterestOnly, 
                balance: 0 
              });
            } else {
              newSchedule.push({ 
                month, 
                payment: monthlyInterestOnly, 
                principal: 0, 
                interest: monthlyInterestOnly, 
                balance 
              });
            }
          }
          break;
      }

      return newSchedule;
    } else {
      return [];
    }
  }, [loanAmount, loanTerm, annualInterestRate]);

  useEffect(() => {
    if (loanAmount && loanTerm && annualInterestRate && loanAmount > 0 && loanTerm > 0 && annualInterestRate > 0) {
      const newSchedule = calculateSchedule(repaymentMethod);
      setSchedule(newSchedule);
      
      const headerHeight = 40;
      const inputAreaHeight = 240;
      const tableHeaderHeight = 40;
      const rowHeight = 37;
      const footerHeight = 40; // 添加表格底部总计行的高度
      const visibleRows = Math.min(12, loanTerm);
      const tableHeight = tableHeaderHeight + (rowHeight * visibleRows) + footerHeight;
      const bottomPadding = 40;
      const newHeight = headerHeight + inputAreaHeight + tableHeight + bottomPadding;
      
      setLocalSize(prevSize => ({ ...prevSize, height: newHeight }));
    } else {
      setSchedule([]);
    }
  }, [loanAmount, loanTerm, annualInterestRate, repaymentMethod, calculateSchedule]);

  useEffect(() => {
    updateCalculator(id, { size: localSize });
  }, [id, localSize, updateCalculator]);

  const handleStart = (e: React.MouseEvent | React.TouchEvent) => {
    bringToFront(id);
    if (calculatorRef.current && (e.target === calculatorRef.current || (e.target as HTMLElement).closest('.calculator-header'))) {
      setIsDragging(true);
      const rect = calculatorRef.current.getBoundingClientRect();
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      setDragOffset({
        x: clientX - rect.left,
        y: clientY - rect.top
      });
    } else if ((e.target as HTMLElement).classList.contains('resize-handle')) {
      setIsResizing(true);
      e.preventDefault();
    }
  };

  const handleMove = useCallback((e: MouseEvent | TouchEvent) => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    if (isDragging && calculatorRef.current) {
      const newX = clientX - dragOffset.x;
      const newY = clientY - dragOffset.y;
      updateCalculator(id, { position: { x: newX, y: newY } });
    } else if (isResizing && calculatorRef.current) {
      const rect = calculatorRef.current.getBoundingClientRect();
      const newWidth = Math.max(300, clientX - rect.left);
      const newHeight = Math.max(400, clientY - rect.top);
      setLocalSize({ width: newWidth, height: newHeight });
    }
  }, [isDragging, isResizing, dragOffset, id, updateCalculator]);

  const handleEnd = useCallback(() => {
    setIsDragging(false);
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isDragging || isResizing) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleEnd);
      window.addEventListener('touchmove', handleMove);
      window.addEventListener('touchend', handleEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, isResizing, handleMove, handleEnd]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, nextRef: React.RefObject<HTMLInputElement | HTMLSelectElement>) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      e.preventDefault();
      nextRef.current?.focus();
    }
  };

  const handleInterestRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace('。', '.');
    if (validateInterestRate(value)) {
      setInterestRateInput(value);
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        updateCalculator(id, { annualInterestRate: parseFloat(numericValue.toFixed(2)) });
      } else if (value === '') {
        updateCalculator(id, { annualInterestRate: 0 });
      }
    }
  };

  const handleRepaymentMethodChange = (method: RepaymentMethod) => {
    updateCalculator(id, { repaymentMethod: method });
  };

  const handleLoanAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue);

    if (inputValue === '' || (isNaN(numericValue) && inputValue !== '-')) {
      setLoanAmountInput('');
      updateCalculator(id, { loanAmount: 0 });
    } else if (!isNaN(numericValue)) {
      if (numericValue <= 999999999) {
        setLoanAmountInput(inputValue);
        updateCalculator(id, { loanAmount: numericValue });
      } else {
        e.preventDefault();
      }
    }
  };

  const adjustLoanAmount = (amount: number) => {
    const currentAmount = parseFloat(loanAmountInput) || 0;
    const newAmount = Math.max(0, Math.min(currentAmount + amount, 999999999));
    setLoanAmountInput(newAmount.toString());
    updateCalculator(id, { loanAmount: newAmount });
  };

  const handleLoanTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const termValue = value === '' ? 0 : parseInt(value, 10);
      updateCalculator(id, { loanTerm: termValue });
    }
  };

  const formatNumber = useCallback((num: number) => {
    const [intPart, decimalPart] = num.toFixed(2).split('.');
    return (
      <span>
        {intPart}
        <span className="opacity-60 text-xs">.{decimalPart}</span>
      </span>
    );
  }, []);

  const getMethodName = useCallback((method: RepaymentMethod): string => {
    switch (method) {
      case 'equalPrincipalInterest':
        return '等本等息';
      case 'equal':
        return '等额本息';
      case 'principal':
        return '等额本金';
      case 'interestFirst':
        return '先息后本';
      default:
        return '';
    }
  }, []);

  const calculateTotals = useCallback((schedule: any[]) => {
    return schedule.reduce((totals, item) => ({
      totalPayment: totals.totalPayment + item.payment,
      totalInterest: totals.totalInterest + item.interest,
      totalPrincipal: totals.totalPrincipal + item.principal,
    }), { totalPayment: 0, totalInterest: 0, totalPrincipal: 0 });
  }, []);

  const renderScheduleTable = useCallback((schedule: any[], method: RepaymentMethod, isLeftTable: boolean) => {
    const totals = calculateTotals(schedule);
    const textColorClass = isLeftTable ? 'text-red-500' : 'text-green-700';

    return (
      <div className="relative h-full flex flex-col">
        <table className="w-full text-sm">
          <thead className="sticky top-0 bg-white z-10">
            <tr className="bg-gray-100"> {/* 表头背景色 */}
              <th className="p-2 border text-center w-16">月份</th>
              <th className={`p-2 border text-right ${textColorClass}`}>
                <span className="text-xs font-normal">
                  [{getMethodName(method)}]
                </span>{' '}
                月供
              </th>
              <th className="p-2 border text-right">本金</th>
              <th className="p-2 border text-right">利息</th>
              <th className="p-2 border text-right">余额</th>
            </tr>
          </thead>
          <tbody className="overflow-auto">
            {schedule.map((item) => (
              <tr key={item.month}>
                <td className="p-2 border text-center">{item.month}</td>
                <td className={`p-2 border text-right ${textColorClass}`}>
                  {formatNumber(item.payment)}
                </td>
                <td className="p-2 border text-right">{formatNumber(item.principal)}</td>
                <td className="p-2 border text-right">{formatNumber(item.interest)}</td>
                <td className="p-2 border text-right">
                  {formatNumber(Math.abs(item.balance))}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="sticky bottom-0 bg-white z-10">
            <tr className="font-bold bg-gray-100"> {/* 总计行背景色 */}
              <td className="p-2 border text-center">总计</td>
              <td className={`p-2 border text-right ${textColorClass}`}>{formatNumber(totals.totalPayment)}</td>
              <td className="p-2 border text-right">{formatNumber(totals.totalPrincipal)}</td>
              <td className="p-2 border text-right">{formatNumber(totals.totalInterest)}</td>
              <td className="p-2 border text-right">-</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }, [calculateTotals, getMethodName, formatNumber]);

  const calculateInterestDifference = useCallback(() => {
    if (showComparison) {
      const leftSchedule = calculateSchedule(leftMethod);
      const rightSchedule = calculateSchedule(rightMethod);
      const leftTotals = calculateTotals(leftSchedule);
      const rightTotals = calculateTotals(rightSchedule);
      const interestDiff = leftTotals.totalInterest - rightTotals.totalInterest;
      
      const formatNumberToString = (num: number) => {
        const [intPart, decimalPart] = num.toFixed(2).split('.');
        return (
          <span>
            {intPart}
            <span className="opacity-60 text-xs">.{decimalPart}</span>
          </span>
        );
      };

      if (Math.abs(interestDiff) < 0.01) {
        return <span>利息：两种方案一样多</span>;
      }

      const leftColor = 'text-red-500';
      const rightColor = 'text-green-700';

      return (
        <span>
          利息差: {formatNumberToString(Math.abs(interestDiff))} 
          <span className={`text-xs ml-1 ${interestDiff > 0 ? leftColor : rightColor}`}>
            ({interestDiff > 0 ? '左方案多付' : '右方案多付'})
          </span>
        </span>
      );
    }
    return null;
  }, [showComparison, leftMethod, rightMethod, calculateSchedule, calculateTotals]);

  const isInputComplete = useCallback(() => {
    return loanAmount > 0 && loanTerm > 0 && annualInterestRate > 0;
  }, [loanAmount, loanTerm, annualInterestRate]);

  useEffect(() => {
    setShowComparison(localSize.width >= COMPARISON_WIDTH_THRESHOLD);
  }, [localSize.width]);

  const handleColorChange = useCallback((color: string) => {
    updateCalculator(id, { topColor: color });
    setIsColorPickerOpen(false);
  }, [id, updateCalculator]);

  // 在渲染函数中使用正确的方法
  const renderSchedule = () => {
    if (showComparison) {
      return (
        <div className="flex">
          <div className="w-1/2 pr-2">
            {renderScheduleTable(calculateSchedule(leftMethod), leftMethod, true)}
          </div>
          <div className="w-1/2 pl-2">
            {renderScheduleTable(calculateSchedule(rightMethod), rightMethod, false)}
          </div>
        </div>
      );
    } else {
      return renderScheduleTable(calculateSchedule(currentSingleMethod), currentSingleMethod, true);
    }
  };

  return (
    <div 
      ref={calculatorRef}
      className="absolute bg-white rounded-lg shadow-md flex flex-col"
      style={{ 
        left: position.x, 
        top: position.y, 
        zIndex,
        width: localSize.width,
        height: localSize.height,
        border: isSelected ? `2px solid ${topColor}` : 'none'
      }}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
    >
      <div className="flex justify-between items-center p-2 rounded-t-lg calculator-header" style={{ backgroundColor: topColor, cursor: 'move' }}>
        <h2 className="text-lg font-semibold text-gray-800">贷款计算器</h2>
        <button className="text-gray-800" onClick={() => onClose(id)}>
          <X size={20} />
        </button>
      </div>
      <div className="p-3 pt-4 flex-grow overflow-hidden flex flex-col">
        <div className="space-y-3 mb-4">
          <div className="relative">
            {amountScale && (
              <div className="absolute -top-4 left-2 bg-gray-200 text-red-600 text-xs font-bold px-2 py-1 rounded shadow">
                {amountScale}
              </div>
            )}
            <div className="flex items-center h-10">
              <input
                ref={loanAmountRef}
                type="text"
                inputMode="numeric"
                placeholder="贷款本金(元)"
                value={loanAmountInput}
                onChange={handleLoanAmountChange}
                onKeyDown={(e) => {
                  if (loanAmountInput.length >= 9 && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'].includes(e.key)) {
                    e.preventDefault();
                  }
                  handleKeyDown(e, loanTermRef);
                }}
                className="flex-grow h-full p-2 border rounded-l pr-12"
                style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
              />
              {loanAmount > 0 && (
                <span className="absolute right-[140px] top-1/2 transform -translate-y-1/2 text-gray-500">元</span>
              )}
              <div className="flex h-full">
                <div className="flex flex-col border-t border-b border-r relative">
                  <span className="absolute text-gray-400 text-[0.6rem] left-1/2 transform -translate-x-1/2 -top-3">M</span>
                  <button onClick={() => adjustLoanAmount(1000000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center">▲</button>
                  <button onClick={() => adjustLoanAmount(-1000000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center border-t">▼</button>
                </div>
                <div className="flex flex-col border-t border-b border-r">
                  <button onClick={() => adjustLoanAmount(100000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center">▲</button>
                  <button onClick={() => adjustLoanAmount(-100000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center border-t">▼</button>
                </div>
                <div className="flex flex-col border-t border-b border-r">
                  <button onClick={() => adjustLoanAmount(10000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center">▲</button>
                  <button onClick={() => adjustLoanAmount(-10000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center border-t">▼</button>
                </div>
                <div className="flex flex-col border-t border-b border-r relative">
                  <span className="absolute text-gray-400 text-[0.6rem] left-1/2 transform -translate-x-1/2 -top-3">K</span>
                  <button onClick={() => adjustLoanAmount(1000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center">▲</button>
                  <button onClick={() => adjustLoanAmount(-1000)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center border-t">▼</button>
                </div>
                <div className="flex flex-col border-t border-b border-r rounded-r">
                  <button onClick={() => adjustLoanAmount(100)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center">▲</button>
                  <button onClick={() => adjustLoanAmount(-100)} className="flex-1 px-2 text-xs hover:bg-gray-100 flex items-center justify-center border-t">▼</button>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <input
              ref={loanTermRef}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              placeholder="贷款期数(月)"
              value={loanTerm || ''}
              onChange={handleLoanTermChange}
              onKeyDown={(e) => handleKeyDown(e, interestRateRef)}
              className="w-full p-2 border rounded pr-8"
            />
            {loanTerm > 0 && (
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500">月</span>
            )}
          </div>
          <div className="relative">
            <input
              ref={interestRateRef}
              type="text"
              placeholder="年利率(%)"
              value={interestRateInput}
              onChange={handleInterestRateChange}
              onKeyDown={(e) => handleKeyDown(e, repaymentMethodRef)}
              className="w-full p-2 border rounded pr-8"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500">%</span>
          </div>
          <div className="mb-4">
            <div className="flex rounded-lg overflow-hidden bg-gray-200 relative">
              {methods.map((method) => (
                <button
                  key={method}
                  className={`flex-1 py-2 px-4 text-sm transition-colors duration-300 ${
                    showComparison
                      ? method === leftMethod
                        ? 'text-red-500'
                        : method === rightMethod
                        ? 'text-green-700'
                        : 'text-gray-700 hover:text-gray-900'
                      : method === currentSingleMethod
                      ? 'text-red-500'
                      : 'text-gray-700 hover:text-gray-900'
                  }`}
                  onClick={() => handleMethodClick(method)}
                >
                  {getMethodName(method)}
                </button>
              ))}
              {showComparison ? (
                <>
                  <div 
                    className="absolute bottom-0 h-0.5 bg-red-500 transition-all duration-300 ease-in-out"
                    style={{
                      left: `${(methods.indexOf(leftMethod) / methods.length) * 100}%`,
                      width: `${(1 / methods.length) * 100}%`,
                    }}
                  ></div>
                  <div 
                    className="absolute bottom-0 h-0.5 bg-green-700 transition-all duration-300 ease-in-out"
                    style={{
                      left: `${(methods.indexOf(rightMethod) / methods.length) * 100}%`,
                      width: `${(1 / methods.length) * 100}%`,
                    }}
                  ></div>
                </>
              ) : (
                <div 
                  className="absolute bottom-0 h-0.5 bg-red-500 transition-all duration-300 ease-in-out"
                  style={{
                    left: `${(methods.indexOf(currentSingleMethod) / methods.length) * 100}%`,
                    width: `${(1 / methods.length) * 100}%`,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="overflow-auto flex-grow">
          {renderSchedule()}
          {!isInputComplete() && (
            <div className="text-center text-sm text-gray-500 mt-2">
              请输入完整的贷款参数
            </div>
          )}
        </div>
      </div>
      <div className="bg-gray-100 py-1 px-2 border-t flex items-center h-8 relative">
        <div className="relative flex items-center">
          <ColorPicker 
            selectedColor={topColor}
            onColorSelect={(color) => updateCalculator(id, { topColor: color })}
          />
        </div>
        <div className="flex-grow flex justify-center">
          <span className="text-xs font-medium">{calculateInterestDifference()}</span>
        </div>
      </div>
      <div 
        className="absolute bottom-0 right-0 w-4 h-4 cursor-se-resize resize-handle"
        style={{ background: 'linear-gradient(135deg, transparent 50%, rgba(0,0,0,0.3) 50%)' }}
      ></div>
    </div>
  );
};

export default LoanCalculator;
